import { ref, computed } from 'vue';

export function usePagination(apiResponse, dispatchFunction) {
    const sorted = ref({
        columnKey: 'date',
        order: 'desc',
    });

    const pagination = computed(() => {
        return apiResponse.value && apiResponse.value.succeeded
            ? {
                showSizeChanger: true,
                current: apiResponse.value.pageNumber,
                total: apiResponse.value.recordsFiltered ?? apiResponse.value.recordsTotal,
                pageSize: apiResponse.value.pageSize,
                orderBy: sorted.value.columnKey,
                orderByDirection: sorted.value.order,
                sorter: sorted.value,
            }
            : {
                showSizeChanger: true,
                current: 1,
                pageSize: 10,
                orderBy: sorted.value.columnKey,
                orderByDirection: sorted.value.order,
                total: 0,
                sorter: sorted.value,
            };
    });

    const onHandleTableChange = (TablePagination, filters, sorter) => {
        let newCurrent = null;
        if (
            Object.keys(sorter).length &&
            (sorted.value.order !== sorter.order || sorted.value.columnKey !== sorter.columnKey)
        ){
            newCurrent = 1;
        }

        sorted.value = sorter;
        pagination.value.current = newCurrent ?? TablePagination.current;
        pagination.value.orderBy = sorted.value.columnKey ?? "";
        pagination.value.orderByDirection = sorted.value.order ?? "";
        pagination.value.sorter = sorter;
        pagination.value.pageSize = TablePagination.pageSize;

        dispatchFunction({
            pagination: pagination.value,
            filters, // You might want to pass filters as well
        });
    };

    return {
        sorted,
        pagination,
        onHandleTableChange,
    };
}
