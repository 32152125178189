<template>
  <RecordViewWrapper>
    <sdPageHeader title="Orders to suplliers" class="custom-header-full">
      <template #subTitle>
        <sdButton class="btn-add_new" size="default" type="primary">
          <router-link to="/purchase/purchase-order">
            <sdFeatherIcons type="plus" size="14" /> Add new
          </router-link>
        </sdButton>
        &nbsp;&nbsp;&nbsp;
        <sdButton @click="switchDeletedView" size="default">
          <sdFeatherIcons v-if="!deletedItems" type="trash" size="14" />
          {{ deletedItems ? "Current" : "Deleted" }} Offers
        </sdButton>
      </template>
    </sdPageHeader>
    <Main>
      <a-row :gutter="15">
        <a-col class="w-100" :md="24">
          <GlobalFilters hasNumberFilter @onFilter="filter"></GlobalFilters>
        </a-col>
      </a-row>
      <a-row :gutter="15">
        <a-col class="w-100" :md="24">
          <sdCards headless>
            <div v-if="isLoading" class="spin">
              <a-spin />
            </div>

            <div v-else>
              <TableWrapper class="table-data-view table-responsive">
                <a-table :loading="isLoading" :dataSource="dataSource" :pagination="pagination" :columns="columns"
                  @change="onHandleTableChange">
                  <template #status="{ record }">
                    <p v-if="record.purchase.purchaseStatus === 1">Draft</p>
                    <p v-if="record.purchase.purchaseStatus === 2">Confirmed</p>
                    <p v-if="record.purchase.purchaseStatus === 3">Canceled</p>
                  </template>
                </a-table>
              </TableWrapper>
            </div>
          </sdCards>
        </a-col>
      </a-row>
    </Main>
  </RecordViewWrapper>
</template>
<script>
import { RecordViewWrapper } from "../../view/crud/axios/style";
import { Main, TableWrapper } from "../styled";
import { useStore } from "vuex";
import { getCurrentInstance, defineAsyncComponent, computed, onMounted, ref, reactive } from "vue";

import moment from "moment";
const GlobalFilters = defineAsyncComponent(() => import("@/components/businessComponents/GlobalFilters.vue"));
import { usePagination } from '@/composable/usePagination.js';

const columns = [
  {
    title: "Partner",
    dataIndex: "purchase.partnerName",
    key: "purchase.partnerName",
  },
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
    sorter: true,
  },
  {
    title: "Number",
    dataIndex: "purchase.number",
    key: "purchase.number",
  },
  {
    title: "Value",
    dataIndex: "purchase.totalNet",
    key: "purchase.totalNet",
  },
  {
    title: "Status",
    key: "status",
    slots: {
      customRender: "status",
    },
  },
  {
    title: "Actions",
    dataIndex: "action",
    key: "action",
    width: "90px",
  },
];

const PurchaseList = {
  name: "PurchaseList",
  components: { GlobalFilters, RecordViewWrapper, Main, TableWrapper },
  setup() {
    const { state, dispatch } = useStore();
    const isLoading = computed(() => state.purchaseApi.loading);
    const selectedRowKeys = ref([]);
    const apiFilters = ref({
      purchasePartenaire: "",
      purchaseDateFrom: "",
      purchaseDateTo: "",
      purchaseNumber: "",
      purchaseType: 1
    });

    const purchaseApi = computed(() => state.purchaseApi.apiResponse);
    const { pagination, onHandleTableChange } = usePagination(
      purchaseApi,
      (params) => {
        if (deletedItems.value) {
          dispatch('purchaseGetAllDeletedData', { ...params, filters: apiFilters.value });
        } else {
          dispatch('purchaseGetAllData', { ...params, filters: apiFilters.value });
        }
      }
    );
    onMounted(() => {
      dispatch("purchaseGetAllData", { pagination: pagination.value, filters: apiFilters.value });
    });

    const deletedItems = ref(false);

    const appContext = getCurrentInstance().appContext;
    const userHasPermission = appContext.config.globalProperties.$userHasPermission;

    const handleDelete = (id) => {
      const confirm = window.confirm("Are you sure delete this?");
      if (confirm) {
        dispatch("purchaseApiDataDelete", id);
      }
      return false;
    };
    const restorItem = (id) => {
      const confirm = window.confirm("Are you sure restore this?");
      if (confirm) {
        dispatch("purchaseApiDataRestore", id);
      }
      return false;
    };
    const updateStatus = (purchase, status) => {
      delete purchase.products;
      purchase.purchaseStatus = status;
      dispatch("purchaseUpdateStatus", { purchase });
    };

    const formState = reactive({
      searchItem: "",
    });

    const onHandleSearch = () => {
      dispatch("contractDataSearch", formState.searchItem);
    };



    const dataSource = computed(() =>
      purchaseApi.value?.data && purchaseApi.value.data.length
        ? purchaseApi.value.data.map((purchase, key) => {
          return {
            key: key + 1,
            purchase,
            date: <div class="">{moment(purchase.date).format("DD/MM/YYYY")}</div>,
            action: generatePurchaseActions(purchase),
          };
        })
        : []
    );

    const generatePurchaseActions = (purchase) => {
      if (!deletedItems.value) {
        return (
          <div class="table-actions">
            <router-link
              class="edit"
              to={"/purchase/edit-purchase-order/" + purchase.id}
            >
              <sdFeatherIcons type="eye" size={14} />
            </router-link>
            {userHasPermission('Permissions.Purchase.Update') && (
              <a-button
                class="check-square"
                onClick={() => updateStatus(purchase, 2)}
              >
                <sdFeatherIcons type="check-square" size={14} />
              </a-button>)}
            &nbsp;&nbsp;&nbsp;
            {userHasPermission('Permissions.Purchase.Update') && (
              <a-popconfirm
                title="Sure to cancel purchase?"
                onConfirm={() => updateStatus(purchase, 3)}
              >
                <a>
                  <sdFeatherIcons type="x-circle" size={14} />
                </a>
              </a-popconfirm>)}
            &nbsp;&nbsp;&nbsp;
            {userHasPermission('Permissions.Purchase.Delete') && (
              <a-button
                class="delete"
                onClick={() => handleDelete(purchase)}
              >
                <sdFeatherIcons type="trash-2" size={14} />
              </a-button>)}
          </div>
        )
      } else {
        return (
          <div class="table-actions">
            <a-button class="delete" onClick={() => restorItem(purchase)}>
              <sdFeatherIcons type="refresh-ccw" size={14} />
            </a-button>
          </div>)
      }
    }

    const filter = (filters) => {
      apiFilters.value.purchasePartenaire = filters.partnerId ?? "";
      apiFilters.value.purchaseNumber = filters.number ?? "";
      apiFilters.value.purchaseDateFrom = filters.dateStart ? moment(filters.dateStart).format('YYYY/MM/DD') : "";
      apiFilters.value.purchaseDateTo = filters.dateEnd ? moment(filters.dateEnd).format('YYYY/MM/DD') : "";
      if (deletedItems.value) {
        dispatch("purchaseGetAllDeletedData", { pagination: pagination.value, filters: apiFilters.value });
      } else {
        dispatch("purchaseGetAllData", { pagination: pagination.value, filters: apiFilters.value });
      }
    }

    const switchDeletedView = () => {
      deletedItems.value = !deletedItems.value;
      apiFilters.value.purchasePartenaire = "";
      apiFilters.value.purchaseNumber = "";
      apiFilters.value.purchaseDateFrom = "";
      apiFilters.value.purchaseDateTo = "";
      if (deletedItems.value) {
        dispatch("purchaseGetAllDeletedData", { pagination: pagination.value, filters: apiFilters.value });
      } else {
        dispatch("purchaseGetAllData", { pagination: pagination.value, filters: apiFilters.value });
      }
    };
    
    return {
      switchDeletedView,
      deletedItems,
      filter,
      isLoading,
      selectedRowKeys,
      onHandleSearch,
      handleDelete,
      dataSource,
      columns,
      formState,
      pagination,
      onHandleTableChange,
    };
  },
};

export default PurchaseList;
</script>
